<script>
import axios from "axios";
import errorHandling from "../../../mixins/errorHandling";
/**
 * Transactions component
 */
export default {
  mixins: [errorHandling],
  data() {
    return {
      loanData: [],
      groupID: this.$route.params.groupID,
      id: this.$route.params.id,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 25, 50],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "accountNo", sortable: true, label: "Loan Account No" },
        { key: "productName", sortable: true, label: "Product Name" },
        {
          key: "shortProductName",
          sortable: true,
          label: "Short Product Name",
        },
        { key: "originalLoan", sortable: true, label: "Original Loan" },
        { key: "loanBalance", sortable: true, label: "Loan Balance" },
        { key: "amountPaid", sortable: true, label: "Amount Paid" },
        { key: "id", label: "Action" },
      ],
      loading: false,
      filterStatus: "active",
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.loanData.length;
    },
    filteredLaons() {
      if (this.filterStatus === "active") {
        return this.loanData.filter((loan) => loan.status.active === true);
      } else if (this.filterStatus === "inactive") {
        return this.loanData.filter((loan) => loan.status.active === false);
      } else {
        return this.loanData;
      }
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.loanData.length;
    this.getCustomerAccount();
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    showActiveLoans() {
      this.filterStatus = "active";
    },
    showInactiveLoans() {
      this.filterStatus = "inactive";
    },

    showAllLoans() {
      this.filterStatus = null;
    },
    async getCustomerAccount() {
      this.loading = true;
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/agent/get-customer-accounts/${this.$route.params.id}`
        );
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.data.loanAccounts
        ) {
          this.loanData = response.data.data.loanAccounts;
          this.loading = false;
        } else {
          this.loading = false;
          this.throwError();
        }
      } catch (error) {
        this.loading = false;
        this.handleError(error.response.data.message);
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="row" v-if="loading">
      <b-spinner class="m-2 m-auto" variant="primary" role="status"></b-spinner>
    </div>
    <div class="card" v-else>
      <div class="card-body">
        <h4 class="card-title mb-4">Loans</h4>
        <div class="mt-4 mb-4 row text-center">
          <div class="col-sm-12 col-md-4 mb-1">
            <b-button variant="secondary" @click.prevent="showInactiveLoans">
              <i class="ri-user-unfollow-line align-middle mr-2"></i> Show
              Inactive Loans
            </b-button>
          </div>
          <div class="col-sm-12 col-md-4 mb-1">
            <b-button variant="secondary" @click.prevent="showActiveLoans">
              <i class="ri-user-follow-line align-middle mr-2"></i> Show Active
              Laons
            </b-button>
          </div>

          <div class="col-sm-12 col-md-4 mb-1">
            <b-button variant="secondary" @click.prevent="showAllLoans">
              <i class="ri-hand-coin-line align-middle mr-2"></i> Show All Loans
            </b-button>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-sm-12 col-md-6">
            <div id="tickets-table_length" class="dataTables_length">
              <label class="d-inline-flex align-items-center">
                Show&nbsp;
                <b-form-select
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                ></b-form-select
                >&nbsp;entries
              </label>
            </div>
          </div>
          <!-- Search -->
          <div class="col-sm-12 col-md-6">
            <div
              id="tickets-table_filter"
              class="dataTables_filter text-md-right"
            >
              <label class="d-inline-flex align-items-center">
                Search:
                <b-form-input
                  v-model="filter"
                  type="search"
                  class="form-control form-control-sm ml-2"
                ></b-form-input>
              </label>
            </div>
          </div>
          <!-- End search -->
        </div>
        <div class="table-responsive">
          <b-table
            :items="filteredLaons"
            :fields="fields"
            responsive="sm"
            stacked="sm"
            :per-page="perPage"
            :current-page="currentPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template v-slot:cell(originalLoan)="row">
              <div class="badge badge-soft-success font-size-14">
                GHS {{ row.value }}
              </div>
            </template>
            <template v-slot:cell(amountPaid)="row">
              <div class="badge badge-soft-warning font-size-14">
                GHS {{ row.value }}
              </div>
            </template>
            <template v-slot:cell(loanBalance)="row">
              <div class="badge badge-soft-danger font-size-14">
                GHS {{ row.value }}
              </div>
            </template>

            <template v-slot:cell(id)="row">
              <router-link
                :to="`/agent/pay/loan/group/${groupID}/${id}/${row.value}`"
              >
                <b-button variant="success">
                  <i class="ri-wallet-line align-middle mr-2"></i> Make Payment
                </b-button>
              </router-link>
            </template>
          </b-table>
        </div>
        <div class="row">
          <div class="col">
            <div class="dataTables_paginate paging_simple_numbers float-right">
              <ul class="pagination pagination-rounded mb-0">
                <!-- pagination -->
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                ></b-pagination>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
